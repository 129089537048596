import App from 'components/App';
import Head from 'next/head';
import React from 'react';
import ScreenProvider from 'components/layout/ScreenProvider';

interface IndexProps {
  page: any;
}
/**
 * The front page of the whole application.
 *
 * @param {IndexProps} props
 * @return {JSX.Element}
 */
const Index = ({
  page,
}: IndexProps): JSX.Element => {
  // TODO Meta information from active profile
  return <>
    <Head>
      <title>Block</title>
    </Head>
    <App>
      <ScreenProvider>
        <div style={{
          width: '100%', textAlign: 'center',
        }}>Block pilot</div>
      </ScreenProvider>
    </App>
  </>;
};

// TODO Make SRI multi tenant system based on Vercel example

// export const getServerSideProps = async () => {
//   const pageBloc = new PageBloc({
//     pageId: 'block',
//   }, (error) => {
//     console.error(error);
//   });

//   const data = await pageBloc.get();

//   return {
//     props: {
//       page: {
//         elements: data?.elements ?? '[]',
//         keywords: data?.keywords ?? '',
//         title: data?.title ?? '',
//         desc: data?.desc ?? '',
//       },
//     },
//     // redirect: {
//     //   destination: NavigationPath.MENU,
//     //   permanent: false,
//     // },
//   };
// };

export default Index;
