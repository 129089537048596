import { useContext } from 'react';
import React from 'react';

export interface ScreenContext {
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;

  userProfileDrawerOpen: boolean;
  setUserProfileDrawerOpen: (value: boolean) => void;
}

export const ScreenContext = React.createContext<ScreenContext>({
  drawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerOpen: () => { },
  userProfileDrawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserProfileDrawerOpen: () => { },
});

const useScreenContext = () => useContext(ScreenContext);

export default useScreenContext;
