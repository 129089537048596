/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthError } from '@mindhiveoy/auth';
import { Media } from '@shared/schema/common';
import { useCallback, useContext } from 'react';
import BackgroundFadeTop from 'components/styling/BackgroundFadeTop';
import ErrorBoundary from 'components/common/ErrorBoundary';
import MediaContainer from 'components/layout/MediaContainer';
import React, { useRef } from 'react';
// import SignIn from 'components/auth/SignIn';
import styled from '@emotion/styled';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentArea = styled.div`
  margin: 0;
  height: 100vh;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
`;

const ScrollAreaRefContext = React.createContext<React.RefObject<HTMLDivElement>>(null);

export const useScrollAreaRef = () => {
  return useContext(ScrollAreaRefContext);
};

export interface AppProps {
  media?: Media;
  blurBackground?: boolean;
}

const App = ({
  blurBackground,
  children,
  media,
}: React.PropsWithChildren<AppProps>): JSX.Element => {
  const scrollAreaRef = useRef();

  const handleError = useCallback((error: Error, renderDefault) => {
    if (error instanceof AuthError && error.errorCode === 'auth_permission_denied') {
      // return <SignIn/>;
      return <div></div>;
    }
    return renderDefault();
  }, []);

  return <AppContainer ref={scrollAreaRef}>
    <BackgroundFadeTop />
    <ScrollAreaRefContext.Provider value={scrollAreaRef}>
      { media && // TODO remove borderRadius from backgrounds
        <MediaContainer fullScreen media={media} blur={blurBackground}/> }
      <ContentArea>
        <ErrorBoundary name="app" onError={handleError}>
          { children }
        </ErrorBoundary>
      </ContentArea>
    </ScrollAreaRefContext.Provider>
  </AppContainer>;
};

export default App;
