import styled from '@emotion/styled';

const BackgroundFadeTop = styled.div<{ disableScroll?: boolean }>(({
  disableScroll,
}) => `
  position: absolute;
  margin: 0;
  background: rgb(2,0,36);
  background: linear-gradient(
    180deg,
     rgba(2, 0, 0, 1) 0%,
     rgba(2, 0, 0, 0.4) 60%,
     rgba(2, 0, 0, 0) 100%
  ); 
  height: 300px;
  width: 100%;
  overflow: ${disableScroll ? 'hidden' : 'auto'};
  `);

export default BackgroundFadeTop;
