import { PropsWithChildren } from 'react';
import { ScreenContext } from './useScreenContext';
import React from 'react';

/**
 * ScreenProvider will share screen level state information between components.
 */
class ScreenProvider extends React.Component<PropsWithChildren<unknown>, ScreenContext> {
  /**
   *
   * @param {PropsWithChildren<unknown>} props Common properties
   */
  constructor(props: PropsWithChildren<unknown>) {
    super(props);

    this.state = {
      drawerOpen: false,
      setDrawerOpen: this.setDrawerOpen,
      userProfileDrawerOpen: false,
      setUserProfileDrawerOpen: this.setUserProfileDrawerOpen,
    };
  }

  /**
   *
   * @param {boolean} drawerOpen True when open
   */
  private setDrawerOpen = (drawerOpen: boolean) => {
    this.setState({
      drawerOpen,
    });
  };

  /**
   *
   * @param {boolean} userProfileDrawerOpen True when open
   */
  private setUserProfileDrawerOpen = (userProfileDrawerOpen: boolean) => {
    this.setState({
      userProfileDrawerOpen,
    });
  };

  /**
   * @return {ReactElement} provider
   */
  render = () => {
    const {
      children,
    } = this.props;

    return <ScreenContext.Provider value={this.state}>
      { children }
    </ScreenContext.Provider>;
  };
}

export default ScreenProvider;
