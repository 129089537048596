import { Media } from '@shared/schema/common';
import { PropsWithChildren } from 'react';
import { useTheme } from '@emotion/react';
import { window } from 'global';
import React, { CSSProperties, MouseEventHandler, useEffect , useRef } from 'react';
import styled, { StyledComponent } from '@emotion/styled';

const BLUR_RADIUS = '32px';

const StyledCard = styled.div<{
  gestures: boolean;
  fullScreen: boolean;
  blur: boolean;
  image: string;
}>(({
  theme,
  fullScreen,
  gestures,
  blur,
  image,
}) =>`
  padding: 0;
  max-height: 100vh;
  height: 100%;
  position: ${fullScreen ? 'fixed' : 'relative'};
  
  ${blur && `filter: blur(${ BLUR_RADIUS });`}
  
  ${fullScreen && 'min-height: 100vh;'}
  ${fullScreen && 'width: 100vw;'}
  transition: all ease-in-out 0.5s;
  background-size: cover;
  background-image: url("${image}");
  border-radius: ${theme.shape.borderRadius}px; 
  overflow: hidden;
  &:hover {
    ${gestures ? theme.animations.hovers.card : ''}
  }
  `);

const VideoCard = styled.div<{
  fullScreen: boolean;
  gestures: boolean;
  blur: boolean;
}>(({
  blur,
  theme,
  fullScreen,
  gestures,
}) =>`
  padding: 0;
  position: ${fullScreen ? 'fixed' :'relative'}; 
  ${blur ? `filter: blur(${ BLUR_RADIUS });` : ''}
  ${fullScreen ? 'min-height: 100vh;' : ''}
  ${fullScreen ? 'width: 100vw;' : 'width: 100%;'}
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  z-index: -1;
  transition: all ease-in-out 0.5s;
  border-radius: ${fullScreen ? 0 : theme.shape.borderRadius}px; 
  &:hover {
    ${gestures ? theme.animations.hovers.card : ''}
  }
  `);

// Any needed to make muted property to video. React is currently missing the attribute.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BackgroundVideo: StyledComponent<any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>, {}> = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
`;

export interface MediaContainerProps {
  media?: Media;
  gestures?: boolean;
  style?: CSSProperties;
  blur?: boolean;
  fullScreen?: boolean;
  onClick?: MouseEventHandler<unknown>;
}
// TODO Support for photo/video show
const MediaContainer = ({
  blur,
  media,
  style,
  children,
  gestures,
  fullScreen,
  onClick,
}: PropsWithChildren<MediaContainerProps>
): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }
    // TODO handle video sid
    const onContentLoaded = () => {
      try {
        if (videoRef?.current) {
          videoRef?.current?.play();
        }
      } catch (error) {
        // TODO: sentry
        console.error(error);
      }
    };

    window.addEventListener('DOMContentLoaded', onContentLoaded);

    if (document.readyState === 'complete') {
      onContentLoaded();
    }
    return () => typeof window !== 'undefined' &&
      window.removeEventListener('DOMContentLoaded', onContentLoaded);
  }, [videoRef,]);

  switch (media?.type) {
    case 'photo':
      return <StyledCard
        fullScreen={fullScreen}
        blur={blur}
        style={{
          ...style,
        }}
        onClick={onClick}

        gestures={gestures}
        image={media?.photoURL ?? theme.shape.imagePlaceholder}>
        { children }
      </StyledCard>;

    case 'video':
      return media?.videoURL ? <VideoCard
        fullScreen={fullScreen}
        blur={blur}
        style={{
          ...style,
        }}
        gestures={gestures}
        onClick={onClick}
      >
        <BackgroundVideo
          loop
          autoPlay
          width={'100%'}
          height={200}
          muted="muted"
          ref={videoRef}
        >
          <source src={media?.videoURL} media="video/mp4"/>
        </BackgroundVideo>
        {children}
      </VideoCard> : <StyledCard
        fullScreen={fullScreen}
        blur={blur}
        style={{
          ...style,
        }}
        onClick={onClick}
        gestures={gestures}
        image={theme.shape.imagePlaceholder}>
        { children }
      </StyledCard>;

    case 'color':
      return <StyledCard
        fullScreen={fullScreen}
        blur={blur}
        style={{
          background: media?.color,
        }}
        onClick={onClick}
        gestures={gestures}
        image={theme.shape.imagePlaceholder}>
        { children }
      </StyledCard>;
    default:
      return <StyledCard
        blur={blur}
        style={style}
        gestures={gestures}
        fullScreen={fullScreen}
        image={theme.shape.imagePlaceholder}
        onClick={onClick}>
        { children }
      </StyledCard>;
  }
};

export default MediaContainer;
